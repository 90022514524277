<template>
  <div class="main">
    <div class="content">
        <WgPhoneText :content="data" />
    </div>
  </div>
</template>
<script>
import WgPhoneText from '../components/Phone/WgPhoneText.vue';
export default {
  name: "PhoneDetail",
  components: { WgPhoneText },
  data(){
    return{
        data: {},
    }
  },
  created() {
    this.getdetail();
  },
  methods: {
    async getdetail(){
        const res = await this.API.basic.getManuscriptDetails(
          this.$route.query.id
        );

        if (res.code === "0") {
          this.data = res.data;
          this.title = res.data.title;
          this.content = res.data.content;
        }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  padding: 180px 30px 30px 30px;
  background: #fff;
  .content {
    box-shadow: 0px 8px 21px 0px rgba(195, 210, 214, 0.4);
  }
}
</style>
