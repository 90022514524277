<template>
  <div class="text">
    <div class="contain">
      <!-- <div
          class="title ellipsis2"
          v-html="content.title"
        ></div> -->
      <div class="day">
        {{ $moment(content.publishTime).format("MM/DD") }}
      </div>
      <div class="year">
        <div class="val">{{ $moment(content.publishTime).format("YYYY") }}</div>
        <div class="source" v-if="content.origin">
          来源：&nbsp;{{ content.origin || "------" }}
        </div>
      </div>
      <div class="title">
        {{ content.title }}
      </div>
      <div class="line"></div>
      <div
        class="content"
        :style="`fontSize:${fontsize}px`"
        v-html="content.content"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      fontsize: "24",
    };
  },
  watch: {},
  mounted() {
    if (this.content?.id) {
      this.content.primersTitle = "";
      this.content.subTitle = "";
      this.getDetails(this.content.id);
    }
  },
  created() {},
  methods: {
    fontSizeClass(type) {
      if (
        (type == 1 && this.fontsize == "24") ||
        (type == 2 && this.fontsize == "20") ||
        (type == 3 && this.fontsize == "16")
      ) {
        return "color:#a12916";
      }
      return "cursor:pointer";
    },
    changFontsize(type) {
      this.fontsize = type == 1 ? "24" : type == 2 ? "20" : "16";
    },
    async getDetails(id) {
      const res = await this.API.basic.getManuscriptDetails(id);
      this.content.primersTitle = res.data.primersTitle || "";
      this.content.subTitle = res.data.subTitle || "";
      this.$forceUpdate();
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #002bfd !important;
}
img {
  max-width: 100%;
}
.text {
  background: url("../../assets/img/phone/bg1.png");
  background-size: 100% 100%;
  .contain {
    padding: 58px 40px 40px 40px;
    .line {
      width: 43px;
      height: 3px;
      background: #af4037;
      margin-bottom: 26px;
    }
    .title {
      font-weight: 500;
      font-size: 36px;
      color: #000000;
      margin-bottom: 35px;
    }
    .day {
      font-weight: 500;
      font-size: 30px;
      color: #000000;
      line-height: 60px;
    }
    .year {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 46px;
      .val {
        font-weight: 400;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
      }
      .source {
        font-weight: 400;
        font-size: 24px;
        color: #666666;
        line-height: 32px;
      }
    }
  }
  .content {
    font-weight: 400;
    color: #666666 !important;
    ::v-deep img {
      margin: auto !important;
    }
    ::v-deep video {
      max-height: 500px;
    }
  }
}
</style>
